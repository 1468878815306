// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apocrypha-js": () => import("./../../../src/pages/apocrypha.js" /* webpackChunkName: "component---src-pages-apocrypha-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-licensing-js": () => import("./../../../src/pages/licensing.js" /* webpackChunkName: "component---src-pages-licensing-js" */),
  "component---src-pages-spanish-js": () => import("./../../../src/pages/spanish.js" /* webpackChunkName: "component---src-pages-spanish-js" */)
}

